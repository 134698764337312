@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.chip-overlay::v-deep {
  .v-overlay__content {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding: $spacer * 2;
    width: 100%;
  }
}
